import React from "react";
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import './dark-mode-toggle.scss';
import DarkModeToggle from "react-dark-mode-toggle";

const DarkModeToggleButton = () => {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => {
        // Don't render anything at compile time. Deferring rendering until we
        // know which theme to use on the client avoids incorrect initial
        // state being displayed.
        if (theme == null) {
          return null
        }
        return (
          <DarkModeToggle
            className='dark-mode-toggle'
            onChange={(e) =>
              toggleTheme(e ? 'dark' : 'light')
            }
            checked={theme === 'dark'}
            size={80}
          />
        )
      }}
    </ThemeToggler>
  )
};

export default DarkModeToggleButton;