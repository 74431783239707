import * as React from 'react';
import { footer } from './footer.module.css';
import { StaticImage } from 'gatsby-plugin-image';

const Footer = () => {
  return (
    <footer className={`${footer} mt-auto py-3 bg-pattern`}>
      <div className='footer-logos'>
        <div class="footer-logo-container">
          QueerTech.io 2022 is supported by
          <a href="https://www.midsumma.org.au/"><StaticImage className='midsumma-logo' src="../../images/logos/midsumma-dark.png" alt="Midsumma Festival logo" /></a>
        </div>
      </div>
    </footer>
  )
}

export default Footer;