import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Footer from '../footer/footer';
import Head from '../head';
import '../../shared.scss';
import DarkModeToggleButton from '../../components/dark-mode-toggle/dark-mode-toggle';

const Layout = ({pageTitle, description, children, ogData}) => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`)

React.useEffect(() => {})
  return (
      <>
        <Head
          pageTitle={pageTitle}
          title={data.site.siteMetadata.title}
          description={description}
          type='article'
          ogData = {ogData}
          />
          <DarkModeToggleButton />
        {/* <Header {...{useSmallHeader}} /> */}
        {/* <main>
          <div className={content}> */}
          {children}
          {/* </div>
        </main> */}
        <Footer />
      </>
  )
}

export default Layout;
