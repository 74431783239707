// From: https://www.faesel.com/blog/gatsby-seo-opengraph-helmet
import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import OGImage from '../images/og-image.png';

import favicon from '../../static/favicon.ico'

const Head = ({ pageTitle, title, description, type, ogData}) => {
    ogData = ogData ? ogData : {};
    const imageAlt = "QueerTech.io Logo";
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    siteUrl,
                    title,
                    description,
                    author
                }
            }
        }
    `)

    return (
        <>
            <Helmet htmlAttributes={{
                lang: 'en',
            }} />
            <Helmet title={`${pageTitle} | ${data.site.siteMetadata.title}`} />
            <Helmet>
                <link rel="icon" href={favicon} />
                <meta name="description" content={ogData.desc ? ogData.desc : description}></meta>
                <meta name="referrer" content="no-referrer-when-downgrade"></meta>

                <meta name="twitter:card" content="summary_large_image"></meta>
                <meta name="twitter:title" content={pageTitle}></meta>
                <meta name="twitter:description" content={ogData.desc ? ogData.desc : description}></meta>
                <meta name="twitter:image" content={ogData.image ? ogData.image: OGImage}></meta>

                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content={data.site.siteMetadata.title} />
                <meta property="og:title" content={pageTitle}></meta>
                <meta property="og:url" content={ogData.link ? ogData.link : data.site.siteMetadata.siteUrl}></meta>
                <meta property="og:description" content={ogData.desc ? ogData.desc : description}></meta>
                <meta property="og:image" content={ogData.image ? ogData.image : OGImage}></meta>
                <meta property="og:image:alt" content={pageTitle}></meta>
                <meta property="og:type" content={type} />
            </Helmet>
        </>
    )
}

export default Head